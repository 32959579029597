import { useEffect, useState } from 'react';
import axios from 'axios';
import styles from '../vistas/estilos/Frame.module.css';
import People from '../assets/people.png';
import Camara from '../assets/camara.png';
import Folder from '../assets/folder.png';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Header from "../componentes/Header";
import NavigationBar from "../componentes/NavigationBar";
import ApiUrl from "../config/ApiUrl";

const Perfil = ({ user }) => {
  const navigate = useNavigate(); // Usar useNavigate para redirección
  const [userId, setUserId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); 
  const [profileImage, setProfileImage] = useState(People);
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [contrasenaActual, setContrasenaActual] = useState('');
  const [nuevaContrasena, setNuevaContrasena] = useState('');
  const [confirmacionContrasena, setConfirmacionContrasena] = useState('');
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const extractedUserId = decodedToken.id_usuario;
      setUserId(extractedUserId);

      // Llama a la API para obtener la información del perfil
      axios
        .get(`${ApiUrl}profile/get-profile`, {
          headers: { 'Authorization': `Bearer ${token}` },
        })
        .then(response => {
          console.log("Respuesta de la API:", response.data);
          if (response.data) {
            const { us_nombre, us_apellido, us_correo, us_foto, telefono } = response.data;
            setNombre(us_nombre);
            setApellido(us_apellido);
            setCorreo(us_correo);
            setProfileImage(us_foto || People);
            setTelefono(telefono);
          }
        })
        .catch(error => console.error("Error cargando la información del perfil:", error));
    } else {
      console.error("No se encontró el token. El usuario debe iniciar sesión.");
    }
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const fileURL = URL.createObjectURL(file);
      setProfileImage(fileURL);
    }
  };

  const handleEditProfile = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert("No tienes permisos para acceder");
      return;
    }

    try {
      const response = await axios.put(
       `${ApiUrl}profile/update-info`, 
        { id_usuario: userId, us_nombre: nombre, us_apellido: apellido, us_correo: correo, telefono: telefono },
        {
          headers: { 'Authorization': `Bearer ${token}` },
        }
      );
      alert('Perfil actualizado correctamente');
    } catch (error) {
      console.error("Error al actualizar perfil:", error);
      alert("Error al actualizar perfil");
    }
  };

  const handleSaveImage = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert("No tienes permisos para acceder");
      return;
    }

    if (!selectedImage) {
      alert("Por favor selecciona una imagen primero");
      return;
    }

    console.log("Enviando imagen con los siguientes datos:");
    console.log("Token:", token);
    console.log("Imagen seleccionada:", selectedImage);

    const formData = new FormData();
    formData.append('us_foto', selectedImage);  
    formData.append('id_usuario', userId); 

    try {
      const response = await axios.put(
        `${ApiUrl}profile/update-profile`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      alert('Imagen guardada correctamente');
    } catch (error) {
      console.error("Error al guardar la imagen:", error);
      alert("Error al guardar la imagen");
    }
  };

  const handleChangePassword = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert("No tienes permisos para acceder");
      return;
    }

    if (nuevaContrasena !== confirmacionContrasena) {
      alert("Las contraseñas no coinciden");
      return;
    }

    try {
      const response = await axios.put(
        `${ApiUrl}profile/update-password`, 
        { 
          id_usuario: userId, 
          contrasena_actual: contrasenaActual, 
          nueva_contrasena: nuevaContrasena, 
          confirmacion_contrasena: confirmacionContrasena 
        },
        {
          headers: { 'Authorization': `Bearer ${token}` },
        }
      );
      alert('Contraseña actualizada correctamente');
      setContrasenaActual("");
      setNuevaContrasena("");
      setConfirmacionContrasena("");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorMessage = error.response.data.error;
        if (errorMessage === 'La contraseña actual es incorrecta') {
          alert('Error: La contraseña actual ingresada es incorrecta.');
        } else if (errorMessage === 'Las contraseñas no coinciden') {
          alert('Error: La nueva contraseña y la confirmación no coinciden.');
        } else {
          alert(`Error al actualizar la contraseña: ${errorMessage}`);
        }
      } else {
        alert("Error inesperado al actualizar la contraseña");
      }
    }
  };

  const handleRedirectToHome = () => navigate("/vacantes"); // Usar navigate para la redirección
  
  return (
    <div className={styles.frameParent}>
          <div >

        <Header/>
        <NavigationBar user={user} />
        </div>


      <div className={styles.frameGroup}>
        <div className={styles.groupParent}>
          <img className={styles.groupIcon} alt="" src={profileImage} />
          <button className={styles.vectorParent} onClick={() => document.getElementById('fileInput').click()}>
            <img className={styles.vectorIcon} alt="" src={Folder} />
            <span className={styles.elegirArchivo}>Elegir Archivo</span>
          </button>
          <input
            type="file"
            id="fileInput"
            className={styles.hiddenInput}
            onChange={handleFileChange}
            style={{ display: 'none' }} // input esté oculto
          />

      <button className={styles.vectorGroup2} onClick={handleSaveImage}>
        <img className={styles.vectorIcon1} alt="" src={Camara} />
        <div className={styles.elegirArchivo}>Guardar foto</div>
      </button>
          <div className={styles.lizetJazminOlveraGonzlezParent}>
            <div className={styles.postulante}>Postulante</div>
          </div>
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.frameDiv}>
            <div className={styles.datosPersonalesWrapper}>
              <b className={styles.elegirArchivo}>Datos personales</b>
            </div>
            <div className={styles.instanceParent}>
  <div className={styles.nombreParent}>
    <div className={styles.lizetJazminOlvera}>Nombre</div>
    <div className={styles.lizetJazminWrapper}>
      <input
        type="text"
        className={styles.elegirArchivo}
        value={nombre}
        onChange={(e) => setNombre(e.target.value)}
      />
    </div>
  </div>
  <div className={styles.nombreParent}>
    <div className={styles.lizetJazminOlvera}>Apellido</div>
    <div className={styles.lizetJazminWrapper}>
      <input
        type="text"
        className={styles.elegirArchivo}
        value={apellido}
        onChange={(e) => setApellido(e.target.value)}
      />
    </div>
  </div>
  <div className={styles.nombreParent}>
    <div className={styles.lizetJazminOlvera}>Correo</div>
    <div className={styles.lizetJazminWrapper}>
      <input
        type="email"
        className={styles.elegirArchivo}
        value={correo}
        onChange={(e) => setCorreo(e.target.value)}
      />
    </div>
  </div>
  <div className={styles.nombreParent}>
    <div className={styles.lizetJazminOlvera}>Teléfono</div>
    <div className={styles.lizetJazminWrapper}>
      <input
        type="tel"
        className={styles.elegirArchivo}
        value={telefono}
        onChange={(e) => setTelefono(e.target.value)}
        />
    </div>
  </div>
</div>

          </div>
          <button
            className={styles.editarPerfilWrapper} 
            onClick={handleEditProfile} 
            type="button"
            style={{ textTransform: 'none' }} // Sobrescribe la transformación de texto
          >
            <b className={styles.editarPerfil}>Editar perfil</b>
          </button>

         
        </div>
      </div>
      <div className={styles.editarContraseaParent}>
        <b className={styles.editarContrasea}>Editar contraseña</b>
        <div className={styles.frameParent1}>
          <div className={styles.instanceGroup}>
            <div className={styles.nombreParent}>
              <div className={styles.lizetJazminOlvera}>Contraseña actual</div>
              <div className={styles.lizetJazminWrapper}>
              <input
                id="contrasena_actual"
                type="password"
                className={styles.elegirArchivo}
                placeholder="Ingrese contraseña"
                value={contrasenaActual}
                onChange={(e) => setContrasenaActual(e.target.value)} // Actualiza el estado
              />
              </div>
            </div>
            <div className={styles.nombreParent}>
              <div className={styles.lizetJazminOlvera}>Nueva contraseña</div>
              <div className={styles.lizetJazminWrapper}>
              <input
                id="nueva_contrasena"
                type="password"
                className={styles.elegirArchivo}
                placeholder="Ingrese la nueva contraseña"
                value={nuevaContrasena}
                onChange={(e) => setNuevaContrasena(e.target.value)} // Actualiza el estado
              />
              </div>
            </div>
          </div>
          <div className={styles.nombreParent}>
            <div className={styles.lizetJazminOlvera}>Confirme contraseña</div>
            <div className={styles.lizetJazminWrapper}>
            <input
              id="confirmacion_contrasena"
              type="password"
              className={styles.elegirArchivo}
              placeholder="Confirme la contraseña"
              value={confirmacionContrasena}
              onChange={(e) => setConfirmacionContrasena(e.target.value)} // Actualiza el estado
            />
            </div>
          </div>
        </div>
        <button
          className={styles.editarContraseaWrapper} 
          onClick={handleChangePassword}
          type="button"
          style={{ textTransform: 'none' }}
        >
          <b className={styles.editar}>Editar contraseña</b>
        </button>
      </div>
    </div>
  );
};

export default Perfil;
