import React, { useState, useEffect } from "react";
import "../vistas/estilos/vacantes.css";
import NavigationBar from "../componentes/NavigationBar";
import Header from "../componentes/Header";
import ApiUrl from "../config/ApiUrl";
import { useNavigate } from 'react-router-dom'; 
import Modal from "../vistas/modales/ModalPostulacion";  // Importa el componente Modal

const Vacantes = ({ user }) => {
  const [vacantes, setVacantes] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedVacante, setSelectedVacante] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVacantes = async () => {
      try {
        const response = await fetch(`${ApiUrl}vacantes`);
        const data = await response.json();
        setVacantes(data);
      } catch (error) {
        console.error("Error al obtener las vacantes:", error);
      }
    };
    fetchVacantes();
  }, []);

  // Función para abrir la ventana modal
  const handlePostular = (vacante) => {
    setSelectedVacante(vacante); // Guardar vacante seleccionada
    setModalOpen(true); // Abrir la modal
  };

  const handleConfirmarPostulacion = () => {
    navigate(`/formularios/${selectedVacante.id_vacante}`); // Redirigir a la vista de formularios
    setModalOpen(false); // Cerrar la modal
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Cerrar la modal
  };

  return (
    <div className="vacantes-container">
      <Header/>
      <NavigationBar user={user} />
      <h1 className="section-title">Vacantes Disponibles</h1>
      <div className="vacantes-grid">
        {vacantes.length > 0 ? (
          vacantes.map((vacante) => (
            <button
              className="vacante-card"
              key={vacante.id_vacante}
              onClick={() => handlePostular(vacante)} // Llamar al manejador de postulación
            >
              <h2 className="vacante-titulo">{vacante.vac_nombre}</h2>
              <p className="vacante-descripcion">{vacante.vac_descripcion}</p>
              <p className="vacante-sueldo">
                Sueldo: ${vacante.sueldoMensual.toFixed(2)}
              </p>
              <p className="vacante-fechas">
                Inicio: {vacante.fecha_inicio} <br />
                Fin: {vacante.fecha_fin}
              </p>
            </button>
          ))
        ) : (
          <p>No hay vacantes disponibles</p>
        )}
      </div>

      {/* Modal de confirmación */}
      <Modal 
        isOpen={modalOpen} 
        onConfirm={handleConfirmarPostulacion} 
        onClose={handleCloseModal} 
      />
    </div>
  );
};

export default Vacantes;
