import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./estilos/Formularios.css";
import NavigationBar from "../componentes/NavigationBar";
import Header from "../componentes/Header";
import ApiUrl from "../config/ApiUrl";

const Formularios = ({ user }) => {
  const [formularios, setFormularios] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedFormulario, setSelectedFormulario] = useState(null);
  const { vacante_id } = useParams();
  const navigate = useNavigate();

  // Recuperar IDs de formularios desactivados desde localStorage
  const getDesactivadosFromLocalStorage = () => {
    const desactivados = localStorage.getItem("formulariosDesactivados");
    return desactivados ? JSON.parse(desactivados) : [];
  };

  // Guardar IDs de formularios desactivados en localStorage
  const saveDesactivadosToLocalStorage = (ids) => {
    localStorage.setItem("formulariosDesactivados", JSON.stringify(ids));
  };

  // Estado para IDs de formularios desactivados
  const [formulariosDesactivados, setFormulariosDesactivados] = useState(
    getDesactivadosFromLocalStorage()
  );

  // Fetch the formularios based on the vacante_id
  useEffect(() => {
    if (!vacante_id) {
      console.error("Falta el id_vacante en la URL");
      return;
    }

    fetch(`${ApiUrl}formularios?vacante_id=${vacante_id}`)
      .then((response) => response.json())
      .then((data) => {
        // Marcar como desactivados aquellos IDs presentes en localStorage
        const updatedFormularios = data.map((formulario) => ({
          ...formulario,
          activo: !formulariosDesactivados.includes(formulario.id_formulario),
        }));
        setFormularios(updatedFormularios);
      })
      .catch((error) => console.error("Error al obtener formularios:", error));
  }, [vacante_id, formulariosDesactivados]);

  // Open the modal with the selected formulario
  const handleFormularioClick = (formulario) => {
    if (!formulario.activo) {
      console.log("Este formulario ya está desactivado.");
      return;
    }
    setSelectedFormulario(formulario);
    setShowModal(true);
  };

  // Start the selected formulario and navigate to its cuestionario
  const handleEmpezar = () => {
    if (selectedFormulario) {
      // Desactivar el formulario localmente
      const updatedDesactivados = [...formulariosDesactivados, selectedFormulario.id_formulario];
      setFormulariosDesactivados(updatedDesactivados);
      saveDesactivadosToLocalStorage(updatedDesactivados);

      // Navegar al cuestionario
      navigate(`/cuestionario/${selectedFormulario.id_formulario}`);
    }
    setShowModal(false);
  };

  // Close the modal without starting
  const handleCancelar = () => {
    setSelectedFormulario(null);
    setShowModal(false);
  };

  return (
    <div className="vacantes-container">
      <Header />
      <NavigationBar user={user} />
      <div className="formularios-container">
        <h1>Formularios</h1>
        <div className="formularios-list">
          {formularios.length > 0 ? (
            formularios.map((formulario) => (
              <div
                key={formulario.id_formulario}
                className={`formulario-card ${
                  formulario.activo ? "activo" : "desactivado"
                }`}
                onClick={() => handleFormularioClick(formulario)}
              >
                <h2>{formulario.for_nombre}</h2>
                <p>{formulario.for_descripcion}</p>
                {!formulario.activo && (
                  <p className="estado">Formulario completado</p>
                )}
              </div>
            ))
          ) : (
            <p>No hay formularios disponibles para esta vacante.</p>
          )}
        </div>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>¿Quieres empezar con el formulario?</h2>
            <p>Dispones de 1 hora para terminar.</p>
            <div className="modal-buttons">
              <button className="btn btn-green" onClick={handleEmpezar}>
                Empezar
              </button>
              <button className="btn btn-red" onClick={handleCancelar}>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Formularios;
