import React, { useEffect, useState } from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, RadialLinearScale, Title, Tooltip, Legend, PointElement, LineElement, Filler } from 'chart.js';
import { useParams } from 'react-router-dom';  // Usar useParams
import Header from "../componentes/Header";
import NavigationBar from "../componentes/NavigationBar";
import ApiUrl from "../config/ApiUrl";

ChartJS.register(CategoryScale, RadialLinearScale, Title, Tooltip, Legend, PointElement, LineElement, Filler);

const Resultados = ({ user }) => {
  const { id_candidato, tipo_dato } = useParams();  // Obtener parámetros de la URL
  const [resultados, setResultados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${ApiUrl}resultados`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error en la respuesta del servidor: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setResultados(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error al obtener los resultados:', error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Cargando resultados...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const obtenerDatosGrafica = () => {
    // Datos fijos según el tipo de dato
    const habilidadesFijas = ["Liderazgo", "Trabajo en Equipo", "HTML", "CSS"];
    const promediosFijos = tipo_dato === '80' ? [80, 75, 95, 80] : [88, 92, 94, 92];

    return {
      labels: habilidadesFijas,
      datasets: [
        {
          label: 'Promedio de Respuestas Correctas (%)',
          data: promediosFijos,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    };
  };

  return (
    <div
      className="resultados-container"
      style={{
        padding: '20px',
        backgroundColor: '#f9f9f9',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflow: 'auto',
      }}
    >
      <Header />
      <NavigationBar user={user} />
      <h2
        style={{
          textAlign: 'center',
          fontSize: '28px',  // Aumentar el tamaño del título
          color: '#333',
          marginBottom: '20px',
        }}
      >
        Resultados del Candidato {id_candidato}
      </h2>

      <div
        className="grafica-container"
        style={{
          height: '50vh',  // Aumentamos el tamaño de la gráfica
          width: '100%',
        }}
      >
        <Radar
          data={obtenerDatosGrafica()}
          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: `Promedio de Respuestas para el Candidato ${id_candidato}`,
              },
            },
            scales: {
              r: {
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 10,
                },
              },
            },
          }}
        />
      </div>

      {/* Mostrar habilidades y promedios */}
      <div
        style={{
          marginTop: '40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {["Liderazgo", "Trabajo en Equipo", "HTML", "CSS"].map((habilidad, idx) => {
          const promediosFijos = tipo_dato === '80' ? [80, 75, 95, 80] : [88, 92, 94, 92];
          return (
            <div key={idx} className="formulario-resultados" style={{ marginBottom: '20px' }}>
              <h4
                style={{
                  fontSize: '22px',  // Aumentamos el tamaño de los títulos
                  color: '#444',
                  fontWeight: 'bold',
                }}
              >
                Habilidad: {habilidad}
              </h4>
              <p
                style={{
                  fontSize: '20px',  // Aumentamos el tamaño del texto
                  color: '#666',
                }}
              >
                Promedio de respuestas correctas: {promediosFijos[idx]}%
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Resultados;
