import React from "react";
import "../estilos/vacantes.css";  // Asegúrate de que los estilos de modal se mantengan

const ModalPostulacion = ({ isOpen, onConfirm, onClose }) => {
  if (!isOpen) return null; // Si no está abierto, no renderizamos el modal

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>¿Quieres postularte a esta vacante?</h3>
        <div className="modal-buttons">
          <button className="confirm-btn" onClick={onConfirm}>
            Sí
          </button>
          <button className="cancel-btn" onClick={onClose}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalPostulacion;
