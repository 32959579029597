import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  // Importar useNavigate
import ApiUrl from '../config/ApiUrl';

const TablaCandidatos = () => {
  const [vacantes, setVacantes] = useState([]);
  const [candidatos, setCandidatos] = useState([]);
  const [selectedVacante, setSelectedVacante] = useState('');
  const navigate = useNavigate();  // Inicializar useNavigate

  useEffect(() => {
    const fetchVacantes = async () => {
      try {
        const response = await fetch(`${ApiUrl}VacanteEmpresa`);
        const data = await response.json();
        setVacantes(data);
      } catch (error) {
        console.error('Error al obtener las vacantes:', error);
      }
    };
    fetchVacantes();
  }, []);

  useEffect(() => {
    const fetchCandidatos = async () => {
      if (selectedVacante) {
        try {
          const response = await fetch(`${ApiUrl}Postulantes?id_vacante=${selectedVacante}`);
          const data = await response.json();
          setCandidatos(data);
        } catch (error) {
          console.error('Error al obtener los postulantes:', error);
        }
      }
    };
    fetchCandidatos();
  }, [selectedVacante]);

  const handleVerGrafica = (id_candidato, index) => {
    // Calcular el tipo de dato alternado (80 o 92)
    const tipoDeDato = index % 2 === 0 ? 80 : 92;
    // Redirigir a la página de resultados con los parámetros adecuados
    navigate(`/resultados/${id_candidato}/${tipoDeDato}`);
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <label htmlFor="vacante" style={{ display: 'block', marginBottom: '10px', fontWeight: 'bold' }}>
        Selecciona una vacante:
      </label>
      <select
        id="vacante"
        value={selectedVacante}
        onChange={(e) => setSelectedVacante(e.target.value)}
        style={{
          padding: '10px',
          marginBottom: '20px',
          width: '100%',
          maxWidth: '400px',
          fontSize: '16px',
          borderRadius: '8px',
          border: '1px solid #C9C9C9',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <option value="">-- Selecciona una vacante --</option>
        {vacantes.map((vacante) => (
          <option key={vacante.id_vacante} value={vacante.id_vacante}>
            {vacante.vac_nombre}
          </option>
        ))}
      </select>
      <div style={{ overflowX: 'auto' }}>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            marginTop: '20px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <thead>
            <tr style={{ backgroundColor: 'black', color: 'white', textAlign: 'center' }}>
              <th style={{ padding: '15px', fontWeight: 'bold', textTransform: 'uppercase' }}>Nombre</th>
              <th style={{ padding: '15px', fontWeight: 'bold', textTransform: 'uppercase' }}>Apellido</th>
              <th style={{ padding: '15px', fontWeight: 'bold', textTransform: 'uppercase' }}>Total</th>
              <th style={{ padding: '15px', fontWeight: 'bold', textTransform: 'uppercase' }}>Correo</th>
              <th style={{ padding: '15px', fontWeight: 'bold', textTransform: 'uppercase' }}>Teléfono</th>
              <th style={{ padding: '15px', fontWeight: 'bold', textTransform: 'uppercase' }}>Ver Gráfica</th>
            </tr>
          </thead>
          <tbody>
            {candidatos.length === 0 ? (
              <tr>
                <td
                  colSpan="6"
                  style={{
                    padding: '15px',
                    textAlign: 'center',
                    fontStyle: 'italic',
                    color: 'black',
                  }}
                >
                  No hay postulantes para esta vacante.
                </td>
              </tr>
            ) : (
              candidatos.map((dato, index) => (
                <tr key={dato.id_candidato} style={{ borderBottom: '1px solid #C9C9C9' }}>
                  <td style={{ padding: '15px', fontSize: '16px' }}>{dato.us_nombre}</td>
                  <td style={{ padding: '15px', fontSize: '16px' }}>{dato.us_apellido}</td>
                  <td style={{ padding: '15px', fontSize: '16px' }}>{dato.totalcompleto}%</td>
                  <td style={{ padding: '15px', fontSize: '16px' }}>{dato.us_correo}</td>
                  <td style={{ padding: '15px', fontSize: '16px' }}>{dato.telefono}</td>
                  <td style={{ padding: '15px', fontSize: '16px' }}>
                    <button
                      onClick={() => handleVerGrafica(dato.id_candidato, index)}
                      style={{
                        padding: '8px 16px',
                        backgroundColor: '#592B87',
                        color: 'white',
                        border: 'none',
                        borderRadius: '8px',
                        cursor: 'pointer',
                      }}
                    >
                      Ver gráfica
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TablaCandidatos;